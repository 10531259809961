div.Container{
    padding: 5%;
    text-align: center;
    width: 210px;
    height: 170px;
    border-radius: 5px;
    background-color: #FFFF;
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -1px -1px 4px rgb(202, 202, 202,0.66) ;
    position: relative;

    div.info{
        position: absolute;
        width: max-content;
        height: max-content;
        top: 10px;
        left: 10px;

        cursor: pointer;
    }

    div.ShopImage{
        img{
            /* border: 1px solid red; */
            width: 70%;
            cursor: pointer;
        }
    }
    
    div.NameAndAddress{
        /* border: 1px solid red; */
        cursor: pointer;
        div.ShopName{
            span{
                font-size: 15px;
                font-weight: 500;
                text-overflow: ellipsis;
            }
        }
    
        div.ShopAddress{
            display: flex;
            justify-content: center;
            align-items: center;
            /* gap: 5px; */


            div.ShopIconAddress{
                margin-left: 5px;
                img{
                    width: 10px;
                }
            }
            
            div.ShopTextAddress{
                span{
                    font-size: 10px;
                    text-overflow: ellipsis;
                }
            }
    

        }
    }

}

div.Container:hover{
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -10px -10px 15px rgb(202, 202, 202,0.16) ;
}


@media (max-width: 550px){
    div.Container{
        /* background-color: red; */
        width: 150px;
        height: 140px;

    }
}