div.TopContent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 5px; */
    /* margin-top: 15px; */
    
    div.Title{
        color: #FFFFFF;
        font-weight: 500;
        font-size: 23px;
    }

    div.Address{
        margin-top: 5px;
        margin-bottom: 11px;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        background-color: #FFFFFF;
        border: 1px solid black;
        border-radius: 6px;
        width: 320px;
        height: 26px;
        overflow: auto;
        text-overflow: ellipsis; 
        
        span{
            color: #808080;
        }
    }
}

div.BottomContent{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 10px; */
    
    div.AddressUpdateBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        width: 300px;
        height: 50px;
        cursor: pointer;
        span{
            color: #FFFFFF;
            font-family: 'Heebo', sans-serif;
            font-size: 25px;
        }
    }
    div.AddressUpdateBtn:hover{
        opacity: .9;
    }

    div.BackBtn{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        background-color: unset;
        border: 1px solid #FFFFFF;
        width: 300px;
        height: 50px;
        cursor: pointer;
        span{
            color: #FFFFFF;
            /* font-weight: 300; */
            font-family: 'Heebo', sans-serif;
            font-size: 25px;
            
        }
    }
    div.BackBtn:hover{
        opacity: .9;
    }
}


@media (max-width: 850px){
    div.TopContent{
        div.Title{
            font-size: 15px;
            text-align: center;
        }
    
        div.Address{
            width: 200px;
            
            span{
                margin-top: 3px;
                font-size: 13px;
            }
        }
    }

    div.BottomContent{
        margin-top: 20px;
        
        div.AddressUpdateBtn{
            width: 170px;
            height: 30px;
    
            span{
                font-size: 15px;
            }
        }
    
        div.BackBtn{
            width: 170px;
            height: 30px;
    
            span{
                font-size: 15px;
            }
        }
    }
}