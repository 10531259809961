div.Container{
    margin: 0;
    padding: 0;
    background: url(../../assets/bg.jpg) no-repeat #FFFFFF;
    background-size: auto;
    /* background-size: resize; */
    background-position: top;
    /* height: 100vh; */

    height: 100vh;
    max-height: 100%;
    overflow:auto;

    div.TopMain{
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;

        div.TopRightBtns{
            display: flex;
            position: absolute;
            right: 7vw;
            /* gap: 20px; */
            div.FilterBtn{
                img{
                    width: 28px;
                }
            }
            div.CancleFilterBtn{
                margin-right: 20px;
                width: 125px;
                height: 28px;
                display: flex;
                justify-content: center !important;
                /* gap: 10px; */
                border-radius: 20px;
                background-color: #00b1e1;
                vertical-align: baseline;
                img{
                    margin-left: 10px;
                    margin-top: 1px;
                    width: 25px;
                }
                span{
                    margin-top: 3px;
                    font-size: 16px;
                    color: #FFFF;
                }
            }
            div.DisabledCancleFilterBtn {}

            div.FilterBtn:hover, div.CancleFilterBtn:hover{
                cursor: pointer;
            }
        }

        div.Title{
            margin-right: 40px;
            font-size: 30px;
            font-weight: 550;
            color: #FFFFFF;
        }
    }
    
    div.ScreenBody{
        padding: 0;
        margin: 0;
        padding-bottom: 50px;

        display: flex;
        justify-content: center;


        -webkit-column-count: 2;
        -moz-column-count: 2;
             column-count: 2;

             div.StoreList{
            height: 100%;
            max-width: 1000px;
            min-width: 360px;

            display: flex;
            flex-wrap: wrap;
            /* gap: 10px; */
            justify-content: center;
            /* padding-bottom: 100%; */
        }

        div.StoreList > * {
            padding: 5px;
        }

        div.Oops{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30vh;
            font-size: 27px;
        }
    }
}

@media (max-width: 800px){
    div.Container{
        margin: 0;
        padding: 0;
        background: url(../../assets/bg.jpg) no-repeat;
        background-size: 1200px;
        /* background-size: 210vh; */
        background-position: top;
        background-color: #FFFFFF;
        
        div.TopMain{
            div.TopRightBtns{
                div.FilterBtn{
                    border: 2px solid #FFFFFF;
                    border-radius: 50px;
                    padding: 5px 6px 0px 5.5px;
                }

                div.CancleFilterBtn{
                    width: 125px;
                    height: 40px;
                    display: flex;
                    justify-content: start !important;
                    padding-right: 10px;
                    /* gap: 10px; */
                    border-radius: 30px 0 0 30px;
                    box-shadow: 4px 4px #00000050;
                    background-color: #00b1e1;
                    vertical-align: baseline;
                    align-items: center;

                    position: fixed;
                    right: -22px;
                    bottom: 5vh !important;

                    img{
                        margin-top: 5px;
                        width: 25px;
                    }
                    span{
                        margin-top: 1px;
                        /* margin-right: 3px; */
                        font-size: 16px;
                        color: #FFFF;
                    }
                }
            }
            
            div.Title{
                margin-right: 40px;
                font-size: 26px;
                font-weight: 550;
                color: #FFFFFF;
            }
        }

        div.ScreenBody{
            div.Oops{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 22vh;
                font-size: 24px;
            }
        }
    }
}