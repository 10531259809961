@media (min-width: 701px) {
  div.Container{
    align-self: center;
    justify-self:right;
  
    div.TopDiv {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      color: var(--mainWhite);
      margin-bottom: 15px;
      cursor: pointer;

      div.Cat{
        div.ItemIcon {
          width: 66px;
          height: 66px;
          border-radius: 50%;
          border: 2px solid var(--mainWhite);
      
          &.ItemIconSelected {
            background-color: var(--mainWhite);
          }
    
          img.ItemImg {
            max-width: 56px;
            max-height: 56px;
            margin-top: 5px;
          }
        }
      
        div.ItemName {
          width: 110px;
          margin-left: -21px;
          margin-right: -21px;
          font-weight: 300;
          font-size: 21px;
        }
      }
    }
  
    &:last-of-type {
      div.TopDiv {
        margin-bottom: 0px;
      }
    }
  }
}

@media (max-width: 700px) {
  div.Container{
    /* border: 1px solid blue; */

    /* align-self: center;
    justify-self:right; */
    height: 100%;
    max-height: 100%;
    div.TopDiv {
      
      height: 100%;
      max-height: 100%;

      display: flex;
      /* flex-direction: column; */
      
      div.ItemIcon {
        width: 50px;
        max-width: 50px;
        max-height: 50px;
        border: 2px solid var(--mainWhite);
        border-radius: 50%;
        color: var(--mainWhite);
        
        &.ItemIconSelected {
          background-color: var(--mainWhite);
        }

        img.ItemImg {
          width: 50px;
          height: 50px;
          margin: 0px;
          padding: 0px;
        }
      }

      div.ItemName {
        margin-top: 2px;
        width: 50px;
        height: 20px;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--mainWhite);
      }

      div.Subcats{
        /* border: 1px solid red; */
        display: flex;
        /* justify-content: center; */
        align-items: center;

        div.SubcatName{
          width: min-content;
          color: #FFF;
          font-size: 13px;
        }
      }

      div.Subcats > *{
        margin-right: 6px;
        margin-left: 6px;
      }
    }
  }
}
