.SocialIcons{
    margin-top: 8px;
}

div.Container{
    align-self: center;

    div.AppTopMenu {
        text-align: center;
        display: flex;
        flex-flow: row-reverse wrap;
        justify-content: space-around;
        margin: 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        /* div.SearchContainer{
            border: 2px solid blue;
            margin-right: 2vw;
        } */
        
    }
    
    div.menubtn {
        padding: 10px;
        background-color: transparent;
        border: 0px;
        color: var(--mainWhite);
        font-size: 24px;
        font-weight: 200;
        cursor: pointer;
    
        &.menubtnActive {
            font-weight: 400;
            text-decoration: underline var(--mainWhite);
        }
    }
    input.searchbox {
        background: no-repeat right;
        background-size: 20px;
        width: 200px;
        cursor: pointer;
        border: 1px solid var(--mainWhite);
        border-radius: 31px;
        color: black;
    }

    div.BigappsLogo{
        /* border: 1px solid red; */
        width: 50%;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: auto;
        cursor: pointer;
        margin-top: 10%;
        opacity: .7;
    }
}